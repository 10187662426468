body {
  font: 14px Segoe, sans-serif;
  margin: 0 auto;
  background: rgb(2, 2, 82);
  color: #fff;
}

.container {
  display: flex;
  justify-content: center;
  height: 100vh;
}

.input,
.output {
  width: 50%;
  padding: 0.9em;
  box-sizing: border-box;
}

.input-text {
  min-height: 510px;
  border: 2px solid #fff;
  background: transparent;
  color: white;
  transition: border 1.5s;
}

.input-text,
.output-text {
  width: 100%;
  box-sizing: border-box;
  min-height: 510px;
  border-radius: 7px;
  padding: 1em;
}

.output-text {
  background: #fff;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.input-text:focus {
  border: 5px solid white;
}

h3 {
  font-weight: normal;
}
